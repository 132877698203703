// import $ from "jquery";
(function()
{

    $(document.body)
    .on("click", ".sharing-button", function()
    {
        var $button = $(this);
        
        window.open($button.data("href"), 'sharing-window', 'width=585,height=655');
    });

})();
