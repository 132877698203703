//adds navigation pips and links them to horizontally scrolling slides
//assumes slides are full width
//container: the element to append the navigation markup
//scrollRoot: element containing slides. slides must be direct descendants
export default function createScrollSnapNavigation(container = null, scrollRoot = null)
{
    if (!container || !scrollRoot) {
        console.log('element doesnt exist');
        return false;
    }
    let currentSlideID;
    let slides = scrollRoot.children;

    //create outer navigation markup
    let navigationCont = document.createElement('div');
    navigationCont.classList.add('slideshow-navigation');
    let navigation = document.createElement('ul');
    navigation.classList.add('unstyled-list');
    container.appendChild(navigationCont).appendChild(navigation);

    //create navigation pips and id data
    for (let i = 0; i < slides.length; i++) {
        let slide = slides[i];
        slide.dataset.id = i;
        let navigationPip = document.createElement('li');
        navigationPip.dataset.id = i;
        navigation.appendChild(navigationPip);
    }
    //click event on navigation pips
    $(navigation).find('li').on('click', function()
    {
        let slide = slides[this.dataset.id];
        scrollToSlide(slide);
    });

    //update navigation pip active state when scrolling
    scrollRoot.addEventListener('scroll', checkActiveSlide);
    checkActiveSlide();

    //reposition current slide after a viewport finishes resizing
    let resizeDebounce;
    window.addEventListener('resize', function()
    {
        clearTimeout(resizeDebounce);
        resizeDebounce = setTimeout(() => 
        {
            scrollToSlide(slides[currentSlideID]);
        }, 300);
    });

    //checks scroll position to set what the current slide should be
    //slides assumed to be full width
    function checkActiveSlide() {
        //baked in 50% width offset
        let slideID = Math.floor((scrollRoot.scrollLeft / scrollRoot.clientWidth) + 0.5);
        //only update the dom if something's changed
        if (slideID !== currentSlideID) {
            $(navigation).children().removeClass('current-slide');
            $(navigation).find("[data-id='" + slideID + "']").addClass('current-slide');
            currentSlideID = slideID;
        }
    }
    //scroll to a slide
    //must use native smooth scrolling instead of a tween, as a per-frame
    //scroll change doesn't play nice with scroll snapping
    function scrollToSlide(slide) {
        if (!slide) {
            return false;
        }
        scrollRoot.scrollTo({
          left: slide.offsetLeft,
          behavior: 'smooth'
        });
    }
}

global.createScrollSnapNavigation = createScrollSnapNavigation;
