const SbPolyfill = polyfillName => {
  switch(polyfillName) {
  case 'intersection-observer':
    // IE Polyfill && Safari < 12.2
    if (!('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      return import(/* webpackChunkName: "intersection-observer" */ "intersection-observer")
    }
    return Promise.resolve();
  default:
    throw Error("unknown polyfill: " + polyfillName);
  }
};

export default SbPolyfill;
